import { atom } from 'recoil';

import { LOCAL_STORAGE_APP_NAV } from '@/lib/constants';
import { setStoredItem } from '@/utils/store/local';

interface AppNavState {
  isOpen: boolean;
  openAccordionItems: string[];
  storedAccordionItems: string[]; // Items get restored from here
}

export const navIsOpenState = atom({
  key: 'navIsOpenState',
  default: false,
});

export const mobileAppNavIsOpenState = atom({
  key: 'mobileAppNavIsOpenState',
  default: false,
});

export const appNavState = atom<AppNavState>({
  key: 'appNavState',
  default: {
    isOpen: true,
    openAccordionItems: ['saved-items'],
    storedAccordionItems: [],
  },
  effects: [
    ({ onSet }) => {
      onSet((newState) => {
        setStoredItem(LOCAL_STORAGE_APP_NAV, newState);
      });
    },
  ],
});
