import { PlaceInterface } from '@/types/api-types';
import { UsersPublicTableInterface } from '@/types/database-types';
import { request } from '@/utils/api/request';
import { getUpdateUserUrl } from '@/utils/helpers/api-urls';

interface UserSettings {
  update: (id: string, settings: UsersPublicTableInterface['settings']) => Promise<PlaceInterface>;
}

export const userSettingsProvider: UserSettings = {
  update: async (id, settings) => {
    const config = {
      body: {
        user: {
          id,
          settings,
        },
      },
    };

    return request(getUpdateUserUrl(), config);
  },
};
