import useSWR from 'swr';

import { UserUsageInterface } from '@/types';
import { AsyncDataInterface } from '@/types/context-types';
import { request } from '@/utils/api/request';
import { getUsageUrl } from '@/utils/helpers/api-urls';

// Poll once every 10 minutes
const USAGE_POLLING_INTERVAL = 1000 * 60 * 10;

interface UseUserUsageInterface extends AsyncDataInterface {
  usage?: UserUsageInterface;
}

export const useUsage = (): UseUserUsageInterface => {
  const { data, isLoading, error } = useSWR<UserUsageInterface>(getUsageUrl, request, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    refreshInterval: USAGE_POLLING_INTERVAL,
  });

  return {
    usage: data,
    isLoading,
    error,
  };
};
